.Main {
  display: flex;
  flex-direction: column;
  background-color: #e1d7c6;
  align-items: center;
  justify-content: space-evenly;
}

.Main p {
  padding: 10px;
  text-align: center;
}

.Main .time {
  padding-top: 25px;
  font-size: 30px;
}

.Main .border-div {
  width: 80%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 100px;
}
.Main .instructions-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  text-align: center;
}

.Main .instructions {
  font-size: 20px;
  padding-bottom: 20px;
  font-style: italic;
}

.Main .random-instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Main button {
  font-size: 17px;
  border-radius: 50px;
  border: 1px solid black;
  padding: 10px;
  background-color: #579bb1;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
}

.Main .random-button {
  margin-top: 20px;
}

.Main .link {
  font-size: 17px;
  text-decoration: none;
  border-radius: 50px;
  border: 1px solid black;
  padding: 10px;
  background-color: #579bb1;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
}

.Main .create-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Main .random-rule {
  font-size: 20px;
  height: 20px;
  font-style: italic;
  margin-bottom: 10px;
}

.Main .sign-in {
  background-color: #e1d7c6;
  border: none;
  border-radius: 0px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.Main .hidden-msg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 120px;
  width: 300px;
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 10px 8px 20px black;
}

@media only screen and (max-width: 768px) {
  .Main {
    height: calc(100vh - 124px);
  }
  .Main .border-div {
    margin: 0;
    padding-bottom: 20px;
  }
  .Main .one {
    border-bottom: none;
  }
  .Main .hidden-msg {
    top: 50%;
    transform: translate(0, -50%);
  }
  .Main .time {
    margin-top: 5px;
    font-size: 24px;
  }
}
