body,
h1,
h2,
p {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  background-color: #e1d7c6;
  font-family: "Signika Negative", sans-serif;
}

button {
  font-family: "Signika Negative", sans-serif;
}
